import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'

import logo from "../images/all-elec-vector4.jpg"

const Header = ({ siteTitle }) => (
  <nav>
    <Container>
    <Navbar className="navbar-light" id="mainNav" expand="lg"
                collapseOnSelect={true}>
          
            <Link className="navbar-brand" to="/">
              <img src={logo} alt={siteTitle} className="logo" width="100%" />
            </Link>
            <Navbar.Toggle aria-controls="navbarResponsive" className="center-margin" />
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="navbar-nav text-center ml-auto" >
              <ul className="navbar-nav  text-center">

                <li><Link to="/about/" className="nav-link" activeClassName="active">About Us</Link></li>

                <NavDropdown title="Services" partiallyActive={true} id="basic-nav-dropdown">
                {/* <Link to="/services/overview/" partiallyActive={true} className="dropdown-item" activeClassName="active">Services</Link> */}
                <Link to="/services/residential-electrical-services/" partiallyActive={true} className="dropdown-item" activeClassName="active">Residential Electrical</Link>
                <Link to="/services/energy-efficiency-installation/" partiallyActive={true} className="dropdown-item" activeClassName="active">Energy Efficiency Installation</Link>
                <Link to="/services/home-automation-installation/" partiallyActive={true} className="dropdown-item" activeClassName="active">Home Automation Electrian</Link>
                <Link to="/services/car-charging-station-installation/" partiallyActive={true} className="dropdown-item" activeClassName="active">Car Charging Station Installation</Link>
                <Link to="/services/commercial-electrician/" partiallyActive={true} className="dropdown-item" activeClassName="active">Commercial Electrician</Link>
              </NavDropdown>
              
                <li><Link to="/testimonials/" className="nav-link" activeClassName="active">Testimonials</Link></li>
             
            
                <li><Link to="/contact/" className="nav-link" activeClassName="active">Contact</Link></li>
              </ul>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Container>
    
    
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
