/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'

import { Container, Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faYelp } from '@fortawesome/free-brands-svg-icons'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="red-top-bg">
        <Container className="text-center">
          <strong>CALL TODAY – (415) 336-7968</strong>
        </Container>
      </div>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main 
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >{children}</main>
        <footer>
          <Container>
          <Row>
            <Col lg={5}>
              <h2>Contact All Electric</h2>
              <p>Office: (415) 336-7968</p>
              <p>Address: 16 Stadium Way, Kentfield, CA 94904</p>
              <p>Email: <a href="mailto:tt.allelectric@gmail.com">tt.allelectric@gmail.com</a></p>
            </Col>
            <Col lg={3}>
              <h2>Navigation</h2>
              <ul className="list-unstyled">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about/">About</Link></li>
                <li><Link to="/services/">Services</Link></li>
                <li><Link to="/testimonials/">Testimonials</Link></li>
                <li><Link to="/contact/">Contact</Link></li>
              </ul>
            </Col>
            <Col lg={4}>
              <h2>Follow Us</h2>
              <a href="https://www.facebook.com/All-Electric-240958982666156/?pnref=lhc">
                    <FontAwesomeIcon icon={faFacebookSquare} aria-label="Facebook" size="2x" className="mr-2" />
                  </a>
                  <a href="https://www.yelp.com/biz/all-electric-kentfield">
                    <FontAwesomeIcon icon={faYelp} aria-label="Yelp" size="2x" className="mr-2" />
                  </a>
              <p className="pt-3"><small>Master electrician and craftsman Thomas Tripolt of All Electric has served the Marin community since 1984. Residential, commercial, remodeling electrical services, charging station installation, home automation installation and energy efficiency solutions. Serving clients throughout Marin County, including: San Rafael, Novato, Kentfield, Larkspur, Sausalito, Tiburon, San Anselmo, Corte Madera, Ross and Fairfax.</small></p>
            </Col>
          </Row>
          <hr />
          <p className="text-center">
          <small>© {new Date().getFullYear()}, All Electric | Site design by <a href="nautilusdesigns.com">Nautilus Designs</a></small>
          </p>
          </Container>
          
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
